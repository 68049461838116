<template>
  <div class="error-page">
    <h2 class="headline text-yellow">404</h2>
    <div class="error-content">
      <br>
      <h3>
        <i class="fa fa-warning text-yellow"></i>
        <span> Oops! Page not found.</span>
      </h3>
      <p>We could not find the page you were looking for.</p>
      <p>Meanwhile, you may <router-link :to="{ name: 'dashboard' }">return to dashboard</router-link>.</p>
    </div><!-- /.error-content -->
  </div><!-- /.error-page -->
</template>

<script>

export default {
  name: 'NotFound',
  metaInfo: {
    title: 'Page Not Found'
  }
}
</script>
